import React, { useState } from "react";

export const ModalContext = React.createContext({});

export default function ModalProvider({ children }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  return (
    <ModalContext.Provider
      value={{ modalIsOpen, setModalIsOpen, modalContent, setModalContent }}
    >
      {children}
    </ModalContext.Provider>
  );
}
