import React from "react";
import Script from "next/script";
import "styles/globals.css";
import { ResizeProvider } from "contexts/ResizeContext";
import ModalProvider from "contexts/ModalContext";

export default function App({ Component, pageProps }) {
  return (
    <>
      {process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID && (
        <>
          {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
            <Script
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
            />
          )}

          <Script
            id={process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID}
            dangerouslySetInnerHTML={{
              __html: `
              window.axeptioSettings = {
                clientId: "${process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID}",
                cookiesVersion: "${process.env.NEXT_PUBLIC_AXEPTIO_COOKIES_VERSION}",
              };
              
              (function(d, s) {
                let t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                e.async = true; e.src = "//static.axept.io/sdk-slim.js";
                t.parentNode.insertBefore(e, t);
              })(document, "script");
            `,
            }}
          />
          <Script
            id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
            dangerouslySetInnerHTML={{
              __html: `
                void 0 === window._axcb && (window._axcb = []);
                window._axcb.push(function(axeptio) {
                  axeptio.on("cookies:complete", function(choices) {
                    if (choices.google_analytics) {
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){ dataLayer.push(arguments); }
                      gtag('js', new Date());
                      gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
                    }
                  })
                })
            `,
            }}
          />
        </>
      )}
      <ResizeProvider>
        <ModalProvider>
          <Component {...pageProps} />
        </ModalProvider>
      </ResizeProvider>
    </>
  );
}
