import React, { createContext, useEffect, useState } from "react";

export const ResizeContext = createContext({});

export function ResizeProvider({ children }) {
  const [breakpoint, setBreakpoint] = useState();
  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
  };

  const handleResize = () => {
    for (let bp in breakpoints) {
      if (window.innerWidth < breakpoints[bp]) {
        setBreakpoint(bp);
        return;
      }
    }

    setBreakpoint("2xl");
    return;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
  }, []);

  return (
    <ResizeContext.Provider value={{ breakpoint, setBreakpoint }}>
      {children}
    </ResizeContext.Provider>
  );
}
